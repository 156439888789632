<template>
    <comp-table title="标签管理" :columns="columns" edit-key="id" :table-api="table_url"
                :delete-api="v => '/gateway/api/sycompany/pc/label/deleteById?labelById=' + v.id"
                :formComponent="dataForm">
        <template v-slot:search="data">
            <FormItem label="标签名称" :label-width="100" style="margin-bottom: 0">
                <Input v-model.trim="data.search.labelName" placeholder="请输入标签名称" style="width: 160px"></Input>
            </FormItem>
        </template>
        <template v-slot:operate>
            <Button type="primary" style="margin-right: 10px"
                    @click="$router.push('/jointly/label/filter?t=community')">社区筛选标签管理
            </Button>
            <Button type="primary" style="margin-right: 10px" @click="$router.push('/jointly/label/filter?t=nearby')">
                附近筛选标签管理
            </Button>
        </template>
    </comp-table>
</template>

<script>
import DataForm from "./form.vue"
import CompTable from "../components/comp-table.vue"

export default {
    components: { CompTable },
    created() {
    },
    data()
{
    return {
        dataForm: DataForm,

        table_url: `/gateway/api/sycompany/pc/label/getLabelByPage?oemCode=`+this.$core.getUserInfo().oemCode+`&labelType=2`,

        columns: [
            {
                title: "标签名称",
                key: "labelName",
                minWidth: 300,
                align: "center",
            },
            {
                title: "排序",
                minWidth: 200,
                align: "center",
                render: (h, params) => {
                    return h("Input", {
                        props: {
                            value: params.row.sort,
                        },
                        attrs: {
                            type: "number",
                            number: "true",
                        },
                        style: {
                            width: "100px",
                        },
                        on: {
                            "on-enter": evt => {
                                this.onChangeSort(params.row.id, evt.target.value)
                            },
                            "on-blur": evt => {
                                this.onChangeSort(params.row.id, evt.target.value)
                            },
                        },
                    })
                },
            },
            {
                title: "编码",
                key: "labelCode",
                minWidth: 200,
                align: "center",
            },
            {
                title: "类型",
                key: "parentId",
                minWidth: 200,
                align: "center",
                render: (h, params) => {

                    return h("div", {}, params.row.parentId == '1' ? '随心购' : '乐龄通')
                },
            },
            {
                title: "创建时间",
                minWidth: 200,
                align: "center",
                render: (h, params) => {
                    if (!params.row.gmtCreate) {
                        return h("div", {}, "-")
                    }

                    return h("div", {}, this.$core.formatDate(new Date(params.row.gmtCreate), "yyyy-MM-dd hh:mm:ss"))
                },
            },
        ],

        //查询参数
        searchForm: {
            keyword: "",
        },
    }
}
,

methods: {
    /**
     * 修改排序
     */
    onChangeSort(id, val)
    {
        if (val < 0) {
            return this.$Message.error({
                content: "排序值不能负数",
                background: true,
            })
        }

        this.$post(`/gateway/api/sycompany/pc/label/updateLabelSeq?id=${id}&sort=${val}`).then(res => {
            if (res.code == 200) {
                this.$Message.success({
                    content: "修改成功",
                    background: true,
                })
            }
        })
    }
,
}
,
}
</script>

<style lang="less" scoped>
.table-left {
    float: left;
    width: 160px;

    /deep/ .ivu-menu {
        width: 160px !important;
    }
}

.none-modal {
    /deep/ .ivu-modal-close {
        display: none;
    }
}
</style>
